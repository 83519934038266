import { sitePhrasing } from "../../../Language/languageHandler"
import { ArrayEntryFieldType } from "../../FormFields/ArrayField/ArrayFieldInput"

export const deliveryOptionValues: any = {
  electronic: true,
  physical: true
}

export const yesOrNoValues = [
  { value: 'yes', label: 'Yes' },
  { value: 'no', label: 'No' },
]

//const companyName = sitePhrasing.businessName ? sitePhrasing.businessName : `${sitePhrasing.state} Certified Document Services`
export const companyName = sitePhrasing.businessName ? sitePhrasing.businessName : `${sitePhrasing.state} Business Filing Center`

export const iAgreeText = `I agree to the terms & conditions and authorize this payment to ${companyName}.
I hereby certify that the information above is correct and that I am authorized to conduct this transaction.`

export const termAndConditionAcceptText = `
  I have read, understand, and agree to be bound by the 
  <a class="link-text" target="_blank" href="/refund-policy/">refund policy</a>, 
  <a class="link-text" target="_blank" href="/privacy-policy/">privacy policy</a>, 
  and <a class="link-text" target="_blank" href="/terms-of-service/">terms of service</a>. 
  I am aware that all payments are final, with the exceptions specified in the return policy.
`;

export const legalStructureOptions = [
  { value: 'Sole Proprietor', label: 'Sole Proprietor' },
  { value: 'Partnerships', label: 'Partnerships' },
  { value: 'C Corporations', label: 'C Corporations' },
  { value: 'S Corporations', label: 'S Corporations' },
  { value: 'LLC', label: 'Limited Liability Company (LLC)' },
  { value: 'Non Profit Organization', label: 'Non Profit Organization' },
]


export const whyRequestingOptions = [
  { value: 'Started new business', label: 'Started a new business' },
  { value: 'Hired employees', label: 'Hires employee(s). Select this option if you have a business and need to hire employees' },
  { value: 'Banking purposes', label: 'Banking purposes' },
  { value: 'Changed type of organization', label: 'Changed type of organization' },
  { value: 'Purchased active business', label: 'Purchased active business' },
]

export const primaryBusinessActivityOptions = [
  { value: "Accommodations" },
  { value: "Construction" },
  { value: "Finance" },
  { value: "Food Service" },
  { value: "Health Care" },
  { value: "Insurance" },
  { value: "Manufacturing" },
  { value: "Real Estate" },
  { value: "Rental & Leasing" },
  { value: "Retail" },
  { value: "Social Assistance" },
  { value: "Transportation" },
  { value: "Warehousing" },
  { value: "Wholesale" },
  { value: "Other" },
]

export const ssnOrItinOptions = [
  { value: 'ssn', label: 'SSN' },
  { value: 'itin', label: 'ITIN' },
]

export const einTaxIdAgreement = `
With my digital signature,
(i) I represent and warrant that all of the information provided above is accurate and complete;
(ii) I agree that I have carefully read and agree to be bound by the Terms of Service, Refund Policy, and Privacy Policy (see below) and
(iii) I have read and understand that I am hereby authorizing ${companyName} as a "Third Party Designee" as described in the instructions
to Form SS-4 to apply to the IRS for the Employer Identification Number of the person or entity listed above,
answer any questions on my behalf or the behalf of the entity listed above about the completion of Form SS-4,
and to receive and deliver to me the Employer Identification Number for me or the entity listed above.`


export const legalNoticeAdvertisementAgreement = `
With my digital signature,
(i) I represent and warrant that all of the information provided above is accurate and complete;
(ii) I agree that I have carefully read and agree to be bound by the Terms of Service, Refund Policy, and Privacy Policy (see below) and
(iii) I have read and understand that I am hereby authorizing ${companyName} as a "Third Party Designee" to publish my fictitious name in a newspaper on my behalf using the information I provided.`

export const feiEinStatusOptions = [
  { value: 'listed', label: 'Listed Above' },
  { value: 'appliedWaiting', label: 'Applied / Waiting For' },
  { value: 'notApplicable', label: 'Not Applicable' },
]

export const blankAuthorizedAgentFn = () => ({
  name: '',
  title: '',
  address: '',
  address2: '',
  city: '',
  state: '',
  zip: '',
})

export const authorizedAgentFields: ArrayEntryFieldType[] = [
  {
    fieldType: 'text',
    fieldName: 'name',
    fieldLabel: 'Person You Are Changing Address For',
    placeholder: 'Name',
    required: true,
  },
  {
    fieldType: 'text',
    fieldName: 'address',
    fieldLabel: 'Street',
    placeholder: 'Street',
    required: true,
  },
  {
    fieldType: 'text',
    fieldName: 'address2',
    fieldLabel: 'Apt #',
    placeholder: 'Apt #'
  },
  {
    fieldType: 'text',
    fieldName: 'city',
    fieldLabel: 'City',
    placeholder: 'City',
    required: true,
  },
  {
    fieldType: 'text',
    fieldName: 'state',
    fieldLabel: 'State',
    placeholder: 'State',
    required: true,
  },
  {
    fieldType: 'text',
    fieldName: 'zip',
    fieldLabel: 'Zip',
    placeholder: 'Zip',
    required: true,
  },
]

export const blankRegisteredAgentFn = () => ({
  name: '',
  title: '',
  address: '',
  address2: '',
  city: '',
  state: '',
  zip: '',
})

export const registeredAgentFields: ArrayEntryFieldType[] = [
  {
    fieldType: 'text',
    fieldName: 'name',
    fieldLabel: 'Name',
    placeholder: 'Name',
    required: true,
  },
  {
    fieldType: 'text',
    fieldName: 'title',
    fieldLabel: 'Title',
    placeholder: 'Title',
    required: true,
  },
  {
    fieldType: 'text',
    fieldName: 'address',
    fieldLabel: 'Street',
    placeholder: 'Street',
    required: true,
  },
  {
    fieldType: 'text',
    fieldName: 'address2',
    fieldLabel: 'Apt #',
    placeholder: 'Apt #'
  },
  {
    fieldType: 'text',
    fieldName: 'city',
    fieldLabel: 'City',
    placeholder: 'City',
    required: true,
  },
  {
    fieldType: 'text',
    fieldName: 'state',
    fieldLabel: 'State',
    placeholder: 'State',
    required: true,
  },
  {
    fieldType: 'text',
    fieldName: 'zip',
    fieldLabel: 'Zip',
    placeholder: 'Zip',
    required: true,
  },
]

export const annualReportTypeOfBusiness = [
  { value: 'LLC', label: 'LLC' },
  { value: 'Corp, INC, other', label: 'Corp, INC, other' },
]


// export const annualReportDisclaimer1 = `I hereby certify that the information supplied with this filing does not qualify for the exemptions contained in Chapter 119, ${sitePhrasing.state} Statutes.
// I hereby certify that the information indicated on this report or supplemental report is true and accurate and that my electronic signature shall have the same legal effect as if made under oath;
// that I am authorized to execute this report as required by Chapter 605, ${sitePhrasing.state} Statutes;
// I am aware that false information submitted in a document to the Department of State constitutes a third degree felony as provided for in s. 817.155 ,
// F.S. I authorize ${companyName} to submit this application on my behalf with the State of ${sitePhrasing.state} `
export const annualReportDisclaimer1 = `With my digital signature, (i) I represent and warrant that all of the information provided above is accurate and complete; (ii) I agree that I have carefully read and agree to be bound by the Terms of Service, Refund Policy, and Privacy Policy (see below) and (iii) I have read and understand that I am hereby authorizing ${companyName} as a "Third Party Designee" to file my annual report on my behalf using the information I provided.`

// export const annualReportDisclaimer2 = `This signature must be that of the individual "signing" this document electronically or be made with the full knowledge and permission of the individual,
// otherwise it constitutes forgery under s. 831.06 , F.S. The individual "signing" this document affirms that the facts stated herein are true.`

// export const annualReportDisclaimer2 = `This signature must be that of the individual "signing" this document electronically or be made with the full knowledge and permission of the individual, otherwise it constitutes forgery. 
// The individual "signing" this document affirms that the facts stated herein are true.


export const annualReportDisclaimer2 = `I hereby certify and state, under penalties of false statement, that all of the information set forth on this annual registration is true. I hereby electronically sign this annual registration.`
export const annualReportNoticeText = 'NOTICE Under s.817.155, F.S., a person may not: '

export const annualReportNoticeList = [
  'Knowingly falsify or conceal a material fact,',
  'Make a false, fictitious, or fraudulent statement or representation, or',
  'Make or use any false document. Anyone violating these conditions is guilty of a third-degree felony and punishable by law. (s.775.082, s.775.083, or s.775.084, F.S.)',
]

export const generalAgreementText = `
With my digital signature, (i) I represent and warrant that all of the information provided above is accurate and complete; 
(ii) I agree that I have carefully read and agree to be bound by the Terms of Service, Refund Policy, 
and Privacy Policy (see below) and (iii) I have read and understand that I am hereby authorizing 
${companyName} as a "Third Party Designee" to obtain the documents 
selected on my behalf using the information I provided.`

export const laborLawPosterAgreementText = `
I understand that the labor law posters provided with this order will reflect the most current laws at the time of purchase and that this is a one-time transaction, not a subscription service. I acknowledge that I will not receive automatic updates regarding changes to the laws, and it is my sole responsibility to stay informed of any mandatory updates and ensure that the posters are updated as needed to maintain compliance.
`

export const mayBeContactedAgreementText = `
I acknowledge that I may be contacted if there are any issues with processing my application. If I am unavailable or unresponsive, I understand that reports may be completed as is, or remain incomplete. I accept full responsibility for promptly attending to any correspondence related to my application.
`

export const electronicSignatureSublabel = `*  The signer must be an individual designated as an authorized member on the business profile. If the signer is not currently listed, they will be added to the profile to fulfill this request. Furthermore, any errors or misspellings in the signature will be corrected during processing, and signatures with incorrect spellings will still be considered valid.`
