export const AnnualReferenceNumberInstructions = () => (
    <>
        <i>Where can I find my reference number?</i> <br />
        If you received a letter in the mail: Top right corner box under the barcode. <br />
        If you received an email: Center box, below your business name & notice date.
    </>
)

export const TermsAndConditionAcceptText = () => {
    return (
        <>
            I have read, understand, and agree to be bound by the
            <a className="link-text" target="_blank" href="/refund-policy/" > refund policy </a>,
            <a className="link-text" target="_blank" href="/privacy-policy/" > privacy policy </a>,
            and <a className="link-text" target="_blank" href="/terms-of-service/" > terms of service </a>.
            I am aware that all payments are final, with the exceptions specified in the return policy.
        </>
    )
}