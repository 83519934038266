import { sitePhrasing } from "../../../Language/languageHandler";
import FloridaAnnualReportFields from "./AnnualReportStateForms/FloridaAnnualReportFields";
import GeorgiaAnnualReportFields from "./AnnualReportStateForms/GeorgiaAnnualReportFields";
import IndianaAnnualReportFields from "./AnnualReportStateForms/IndianaAnnualReportFields";
import MississippiAnnualReportFields from "./AnnualReportStateForms/MississippiAnnualReportFields";
import NorthCarolinaAnnualReportFields from "./AnnualReportStateForms/NorthCarolinaAnnualReportFields";
import TennesseeAnnualReportFields from "./AnnualReportStateForms/TennesseeAnnualReportFields";
import VirginiaAnnualReportFields from "./AnnualReportStateForms/VirginiaAnnualReportFields";

export const AnnualReportFields = (props: {
  errors: any;
  touched: any;
  values: any;
  selectedProducts: any;
  setFieldValue: any;
}) => {
  const {
    values,
    errors,
    touched,
    selectedProducts = {},
    setFieldValue,
  } = props;

  let annualPhrasing = "annual report";

  if (selectedProducts["amend"]) {
    annualPhrasing = "amendment";
  }

  if (selectedProducts["reinstate"]) {
    annualPhrasing = "reinstatement";
  }

  if (sitePhrasing.state === "Florida") {
    return (
      <FloridaAnnualReportFields
        errors={errors}
        touched={touched}
        values={values}
        annualPhrasing={annualPhrasing}
        setFieldValue={setFieldValue}
        selectedProducts={selectedProducts}
      />
    );
  }

  if (sitePhrasing.state === "Georgia") {
    return (
      <GeorgiaAnnualReportFields
        errors={errors}
        touched={touched}
        values={values}
        setFieldValue={setFieldValue}
      />
    )
  }

  if (sitePhrasing.state === "Indiana") {
    return (
      <IndianaAnnualReportFields
        errors={errors}
        touched={touched}
        values={values}
        setFieldValue={setFieldValue}
        selectedProducts={selectedProducts}
      />
    );
  }

  if (sitePhrasing.state === "Mississippi") {
    return (
      <MississippiAnnualReportFields
        errors={errors}
        touched={touched}
        values={values}
        setFieldValue={setFieldValue}
        selectedProducts={selectedProducts}
      />
    );
  }

  if (sitePhrasing.state === "North Carolina") {
    return (
      <NorthCarolinaAnnualReportFields
        errors={errors}
        touched={touched}
        values={values}
        setFieldValue={setFieldValue}
        selectedProducts={selectedProducts}
      />
    );
  }

  if (sitePhrasing.state === "Tennessee") {
    return (
      <TennesseeAnnualReportFields
        errors={errors}
        touched={touched}
        values={values}
        selectedProducts={selectedProducts}
        setFieldValue={setFieldValue}
      />
    );
  }

  if (sitePhrasing.state === "Virginia") {
    return (
      <VirginiaAnnualReportFields
        errors={errors}
        touched={touched}
        values={values}
        selectedProducts={selectedProducts}
        setFieldValue={setFieldValue}
      />
    );
  }


  return (
    <>
      <h3>Annual Report Questions for {sitePhrasing.state} not implemented</h3>
    </>
  );
};
